<template>
  <middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div v-if="requesterData.method">
    <b-row class="mt-1">
      <b-col md="8">
        <label for="custom-input">
          URI
          <helper-tooltip
            size="13"
            innerHTML="Full URL with scheme (https, http), host and path. Like <code>https://myhost.com/my/path</code>"
          />
        </label>
        <custom-input
          v-if="requesterData.uri != undefined && variables"
          id="custom-input"
          v-model="requesterData.uri.register_1"
          :possibleValues="variables"
        />
        <div v-else>Loading...</div>
      </b-col>
      <b-col md="4" class="mt-25">
        <b-form-group :label="$t('Method')" label-for="method">
          <b-form-select
            id="method"
            v-model="requesterData.method.register_1.value"
          >
            <b-form-select-option
              v-for="(option, index) in options"
              :key="index"
              :value="option.script_output"
            >
              {{ $t(option.label) }}
            </b-form-select-option>
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ $t("common.terms.select_please") }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <div class="w-100">
        <b-tabs justified>
          <b-tab title="Headers">
            <key-value
              v-if="variables"
              :ref="getID('header')"
              class="ml-1"
              :addButtonText="$t('+ add new header')"
              v-model="requesterData.headers"
              dropZone
              :possibleValues="variables"
              @deleteItem="
                (payload) => {
                  deleteAgent(payload.item, payload.index, 'header');
                }
              "
              :defaultValue="defaultHeaderValue"
            />
          </b-tab>
          <b-tab title="Queries">
            <key-value
              v-if="variables"
              :ref="getID('query')"
              class="ml-1"
              :addButtonText="$t('+ add new query')"
              v-model="requesterData.queries"
              dropZone
              :possibleValues="variables"
              @deleteItem="
                (payload) => {
                  deleteAgent(payload.item, payload.index, 'query');
                }
              "
              :defaultValue="defaultQueryValue"
            />
          </b-tab>
        </b-tabs>
      </div>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12">
        <hr class="w-100">
        <div class="d-flex justify-content-between">
            <b-form-checkbox
            class="custom-control-primary mb-50"
            v-model="hasBody"
          >
            Has body?
          </b-form-checkbox>
          <b-form-checkbox
            switch
            class="custom-control-primary mb-50"
            v-model="advancedBody"
          >
            Advanced
            <helper-tooltip
              size="15"
              innerText="eopae"
              tooltipPlacement="right"
            />
          </b-form-checkbox>
        </div>
        <b-form-group
          v-if="!advancedBody && variables"
          :label="$t(type === 'json' ? 'middleware.modal.input.label.json_body' : 'middleware.modal.input.label.request_body')"
          label-for="detect-json"
          class="w-100"
          :class="hasBody ? '' : 'json-input-disabled'"
        >
          <drop
            @drop="
              (data) => {
                addingVariable(data, key);
              }
            "
          >
            <json-editor
              v-if="type === 'json'"
              id="detect-json"
              key="detect-json"
              v-model="requesterData.body.register_1.value"
              :modes="['code', 'tree', 'preview']"
              :height="400"
            />

            <key-value
              v-else-if="type === 'form-data' || type === 'url-encoded'"
              :ref="getID('body')"
              class=""
              :addButtonText="$t('+ add new path')"
              v-model="requesterData.body"
              dropZone
              :possibleValues="variables"
              @deleteItem="
                (payload) => {
                  deleteAgent(payload.item, payload.index, 'body');
                }
              "
              :defaultValue="defaultBodyValue"
            />
            <b-tabs v-else-if="type === 'soap'" lazy class="height-400">
              <b-tab title="Header">
                <monaco 
                  class="border"
                  language="xml"
                  height="400px"
                  v-model="requesterData.soapHeader.register_1.value"
                />
              </b-tab>

              <b-tab title="Body">
                <monaco 
                  class="border"
                  language="xml"
                  height="400px"
                  v-model="requesterData.soapBody.register_1.value"
                />
              </b-tab>
            </b-tabs>
            
            <prism-editor v-else class="my-editor height-400" v-model="requesterData.body.register_1.value" :highlight="highlighter" line-numbers></prism-editor>
          </drop>
        </b-form-group>
        <div v-else>
          <actions-reorder-list
            :key="getID(`actions-reorder-list-${version}`)"
            :middlewareID="middlewareID"
            v-model="agents"
            @agentAdded="version++"
            @agentEdited="version++"
            @agentDeleted="version++"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</middleware-sidebar-outline>
</template>


<script>
import {
  BContainer,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BTab,
  BFormSelect,
  BTabs,
  BFormSelectOption,
  BFormCheckbox,
  BSpinner
} from "bootstrap-vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import DraggableItems from "@/views/pages/middleware/MiddlewareManagement/Components/DraggableItems.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { v4 as uuidv4 } from "uuid";
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import Drop from "@/views/pages/middleware/MiddlewareManagement/Components/Drop.vue";
import Methods from "@/custom/class/Enum/Methods.js";
import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
import KeyValue from "@/views/pages/middleware/MiddlewareManagement/Components/Requester/KeyValueItems.vue";
import { makeToast } from "@/layouts/components/Popups";
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js'

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
import 'prismjs/components/prism-xml-doc.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/themes/prism-tomorrow.css';
import Monaco from '@/layouts/components/editor/monaco.vue';

import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'


export default {
  mixins: [MiddlewaresMixin],
  components: {
    BContainer,
    BSidebar,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BForm,
    BTab,
    BTabs,
    DraggableItems,
    CustomInput,
    VuePerfectScrollbar,
    BInputGroupAppend,
    BInputGroupPrepend,
    JsonEditor,
    Drop,
    BFormSelect,
    HelperTooltip,
    BFormSelectOption,
    KeyValue,
    VariablesPanel,
    PrismEditor,
    BFormCheckbox,
    ActionsReorderList,
    BSpinner,
    Monaco,
    MiddlewareSidebarOutline,
  },
  props: {
    i18nKey: {
      type: String,
      default: 'soap_requester'
    },
    uuid: {
      type: String,
      required: true,
    },
    middlewareID: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    
  },
  data() {
    return {
      type: 'soap',
      variables: undefined,
      variant_map: undefined,
      uuidMap: {},
      middlewareType: new Middlewares().items.find(el=> el.id == 36),

      options: new Methods().items,
      requesterData: {
        method: undefined,
        uri: undefined,
        path: undefined,
        soapBody: undefined,
        soapHeader: undefined,
        headers: undefined,
        queries: undefined,
      },

      fieldName: {
        method: 'METHOD',
        uri: 'URI',
        path: 'PATH',
        soapBody: 'BODY',
        soapHeader: 'HEADER',
        headers: 'HEADER',
        queries: 'QUERY',
      },

      typeDefaultValues: {
        json: {},
        xml: '',
        plain: '',
        'form-data': [],
        'url-encoded': [],
        soap: '',
      },
      agentList: [],
      advancedBody: false,
      hasBody: true,
      version: 0,
      isSaving: false,

      defaultAgentBlockID: 1,
      defaultAgentActionID: 3,

      definePayloadModifier(payload, mod){
        return {
          ...payload,
          register_destiny: {
            value: `${mod}.${payload.register_destiny.value}`,
            source: payload.register_destiny.source
          }
        }
      },
    };
  },
  computed: {
    agents: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    defaultHeaderValue() {
      return DefaultAgent.setAgent(this, {
        id: null,
        enum_agent_action_id: this.defaultAgentActionID,
        enum_agent_block_id: this.defaultAgentBlockID,
        register_1: {
          source: 7,
        },
        register_2: {},
        register_destiny: {
          value: '',
          source: 1,
        },
        execution_order: 1,
        fatal_on_fail: true,
      })
    },
    defaultQueryValue() {
      return DefaultAgent.setAgent(this, {
        id: null,
        enum_agent_action_id: this.defaultAgentActionID,
        enum_agent_block_id: this.defaultAgentBlockID,
        register_1: {
          source: 7,
        },
        register_2: {},
        register_destiny: {
          value: '',
          source: 1,
        },
        execution_order: 1,
        fatal_on_fail: true,
      })
    },
    defaultPathValue() {
      return DefaultAgent.setAgent(this, {
        id: null,
        enum_agent_action_id: this.defaultAgentActionID,
        enum_agent_block_id: this.defaultAgentBlockID,
        register_1: {
          source: 7,
        },
        register_2: {},
        register_destiny: {
          value: '',
          source: 1,
        },
        execution_order: 1,
        fatal_on_fail: true,
      })
    },
    defaultBodyValue() {
      return DefaultAgent.setAgent(this, {
        id: null,
        enum_agent_action_id: this.defaultAgentActionID,
        enum_agent_block_id: this.defaultAgentBlockID,
        register_1: {
          source: 7,
        },
        register_2: {},
        register_destiny: {
          value: this.fieldName['body'],
          source: 1,
        },
        execution_order: 1,
        fatal_on_fail: true,
      })
    },

    isBodyList() {
      return this.typeDefaultValues[this.type] instanceof Array
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initializeAgents();
      this.$nextTick(() => { 
        this.setHasBody();
      })
    },
    setHasBody() {
      if (this.isBodyList) return
      let body = this.requesterData.soapBody.register_1.value
      let header = this.requesterData.soapHeader.register_1.value
      if ((!body || !Object.keys(body).length) && (!header || !Object.keys(header).length)) {
        this.hasBody = false
      }
    },
    highlighter(code) {
      let lang = undefined;
      try {
        JSON.parse(code);
        lang = languages.json;
      } catch (e) {
        var xmlString = this.requesterData.soapBody.register_1.value || this.requesterData.soapHeader.register_1.value;
        var domParser = new DOMParser();
        var dom = domParser.parseFromString(xmlString, 'text/xml');

        if(dom.documentElement.nodeName != 'parsererror') {
          lang = languages.xml;
        } else {
          lang = languages.plain;
        }
      }

      return highlight(code, lang);
    },
    configureVars() {
      this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
      this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
    },
    isObject(item) {
      return item instanceof Object && !(item instanceof Array);
    },
    isArray(item) {
      return item instanceof Array;
    },    
    initializeAgents() {

      this.requesterData.uri = MiddlewareFunctions.getValueFrom(
        {
          default: DefaultAgent.setAgent(this, {
            id: null,
            enum_agent_action_id: this.defaultAgentActionID,
            enum_agent_block_id: this.defaultAgentBlockID,
            register_1: {
              source: "7",
            },
            register_2: {},
            register_destiny: {
              source: 1,
              value: this.fieldName['uri'],
            },
            execution_order: 1,
            fatal_on_fail: true,
          }),
          source: 1,
          value: this.fieldName['uri'],
          from: {
            value: "register_destiny",
            source: "register_destiny",
          },
          modifier: false,
          multiple: false,
          expected: {
            source: "7",
            from: "register_1",
          },
        },
        this.agents
      );

      this.requesterData.method = MiddlewareFunctions.getValueFrom(
        {
          default: DefaultAgent.setAgent(this, {
            id: null,
            enum_agent_action_id: this.defaultAgentActionID,
            enum_agent_block_id: this.defaultAgentBlockID,
            register_1: {
              source: "7",
            },
            register_2: {},
            register_destiny: {
              source: 1,
              value: this.fieldName['method'],
            },
            execution_order: 1,
            fatal_on_fail: true,
          }),
          source: 1,
          value: this.fieldName['method'],
          from: {
            value: "register_destiny",
            source: "register_destiny",
          },
          modifier: false,
          multiple: false,
        },
        this.agents
      );

      // this.requesterData.headers = MiddlewareFunctions.getValueFrom(
      //   {
      //     default: [],
      //     source: "1",
      //     from: {
      //       source: "register_destiny",
      //     },
      //     modifier: false,
      //     multiple: true,
      //   },
      //   this.agents
      // );

      this.requesterData.headers = MiddlewareFunctions.getValueFrom(
          {
            default: [],
            source: 1,
            value: `${this.fieldName['headers']}.`,
            from: {
              source: "register_destiny",
              value: "register_destiny",
            },
           
            data_modifier: (el) => {
              return {
                ...el,
                register_destiny: {
                  value: el.register_destiny.value.split('.')[1],
                  source: el.register_destiny.source,
                } 
              };
            },
            modifier: (el) => {
              if (!el) return
              return el.split('.')[0] + '.';
            },
            multiple: true,
          },
          this.agents
        );

      // this.requesterData.queries = MiddlewareFunctions.getValueFrom(
      //   {
      //     default: [],
      //     source: "1",
      //     from: {
      //       source: "register_destiny",
      //     },
      //     modifier: false,
      //     multiple: true,
      //   },
      //   this.agents
      // );

      this.requesterData.queries = MiddlewareFunctions.getValueFrom(
          {
            default: [],
            source: 1,
            value: `${this.fieldName['queries']}.`,
            from: {
              source: "register_destiny",
              value: "register_destiny",
            },
           
            data_modifier: (el) => {
              return {
                ...el,
                register_destiny: {
                  value: el.register_destiny.value.split('.')[1],
                  source: el.register_destiny.source,
                } 
              };
            },
            modifier: (el) => {
              if (!el) return
              return el.split('.')[0] + '.';
            },
            multiple: true,
          },
          this.agents
        );

      //  this.requesterData.path = MiddlewareFunctions.getValueFrom(
      //   {
      //     default: [],
      //     source: "1",
      //     from: {
      //       source: "register_destiny",
      //     },
      //     modifier: false,
      //     multiple: true,
      //   },
      //   this.agents
      // );

      this.requesterData.path = MiddlewareFunctions.getValueFrom(
          {
            default: [],
            source: 1,
            value: `${this.fieldName['path']}.`,
            from: {
              source: "register_destiny",
              value: "register_destiny",
            },
           
            data_modifier: (el) => {
              return {
                ...el,
                register_destiny: {
                  value: el.register_destiny.value.split('.')[1],
                  source: el.register_destiny.source,
                } 
              };
            },
            modifier: (el) => {
              if (!el) return
              return el.split('.')[0] + '.';
            },
            multiple: true,
          },
          this.agents
        );

      this.requesterData.soapBody = MiddlewareFunctions.getValueFrom(
        {
          default: DefaultAgent.setAgent(this, {
            id: null,
            enum_agent_action_id: this.defaultAgentActionID,
            enum_agent_block_id: this.defaultAgentBlockID,
            register_1: {
              source: "7",
              value: this.typeDefaultValues[this.type]
            },
            register_2: {},
            register_destiny: {
              source: 17,
              value: this.fieldName['soapBody'],
            },
            execution_order: 1,
            fatal_on_fail: true,
          }),
          source: 17,
          value: this.fieldName['soapBody'],
          from: {
            value: "register_destiny",
            source: "register_destiny",
          },
          multiple: false,
          data_modifier: false,
        },
        this.agents
      );

      this.requesterData.soapHeader = MiddlewareFunctions.getValueFrom(
        {
          default: DefaultAgent.setAgent(this, {
            id: null,
            enum_agent_action_id: this.defaultAgentActionID,
            enum_agent_block_id: this.defaultAgentBlockID,
            register_1: {
              source: "7",
              value: this.typeDefaultValues[this.type]
            },
            register_2: {},
            register_destiny: {
              source: 17,
              value: this.fieldName['soapHeader'],
            },
            execution_order: 1,
            fatal_on_fail: true,
          }),
          source: 17,
          value: this.fieldName['soapHeader'],
          from: {
            value: "register_destiny",
            source: "register_destiny",
          },
          multiple: false,
          data_modifier: false,
        },
        this.agents
      );
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    buildAgents() {
      this.isSaving = true
      this.agentList = [];
      
      Object.keys(this.requesterData).forEach((key) => {
        switch (true) {
          case (this.requesterData[key] instanceof Array): {
            if (this.requesterData[key].length) {
              this.requesterData[key].forEach((item) => {
                // this.agentList.push(DefaultAgent.defineToAPI(item, this.middlewareID));
                this.agentList.push(DefaultAgent.defineToAPI(this.definePayloadModifier(item, this.fieldName[key]), this.middlewareID));
              });
            }
          } break;
          default: {
            this.agentList.push(DefaultAgent.defineToAPI(this.requesterData[key], this.middlewareID));
          } break;
        }
      })

      this.saveAgents();
    },
    saveAgents() {
      this.$store
        .dispatch("saveAgents", {
          agentList: this.agentList,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          makeToast({
            title: this.$t("agent.toast.create_agents.success.title"),
            text: this.$t("agent.toast.create_agents.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });

          this.$emit("saved", response.data);
          this.isSaving = false

        })
        .catch((error) => {
          console.log(error);
          this.isSaving = true
        });
    },
    deleteAgent(item, index, type) {
      this.$store
        .dispatch("deleteAgent", {
          id: item.id,
          transmissionID: this.$route.params.transmissionID,
        })
        .then(() => {
          makeToast({
            title: this.$t("agent.toast.delete.success.title"),
            text: this.$t("agent.toast.delete.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
        })
        .catch(() => {
          this.$refs[this.getID(type)].addItemAt(item, index);
          makeToast({
            title: this.$t("agent.toast.delete.error.title"),
            text: this.$t("agent.toast.delete.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    defineBody(key, value) {
      let body = this.requesterData[key].register_1.value;

      return {
        agent_id: this.requesterData[key].id || null,
        middleware: this.middlewareID,
        enum_agent_action: 3,
        enum_agent_block_id: 1,
        enum_source_destiny_id: 17,
        destiny_value: value,
        enum_source_register_1: 7,
        register_1_value: body,
        enum_source_register_2: null,
        register_2_value: null,
        fatal_on_fail: true,
      };
    },
    sidebarShown() {
this.$emit("shown")
      let el = document.getElementsByTagName("html");
      el[0].classList.add("hide-scrollbar");
    },
    sidebarHidden() {
      this.$emit("close");

      let el = document.getElementsByTagName("html");
      el[0].classList.remove("hide-scrollbar");
    },
  },
};
</script>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>


<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.height-400 {
  height: 600px !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;
  }

  .limit-height {
    height: calc(100% - 260px) !important;
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.jv-dark {
  background: #161d31;
  white-space: nowrap;
  color: $white;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
  box-shadow: 0 2px 7px rgb(0 0 0 / 15%) !important;
  border-color: transparent !important;
  position: relative !important;

  .jv-ellipsis {
    color: $white;
    background-color: #293148;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 5px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #a6e22e;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: $white;
    }
    &.jv-boolean {
      color: #ae81ff;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #ae81ff;
    }
    &.jv-number-float {
      color: #ae81ff;
    }
    &.jv-number-integer {
      color: #ae81ff;
    }
    &.jv-object {
      color: $white;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #e6db74;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #161d31;
        }
      }
    }
  }
}

.h3-separator {
  color: #ffffff2b !important;
  font-size: 40px;
}

.test-terminal {
  max-height: 80vh;
  .submit-button {
    margin-top: 22px;
  }
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.json-input-disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
}
</style>
